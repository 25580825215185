<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                label-width="100px"
                size="medium"
                @submit.native.prevent
            >
                <el-form-item label="角色" prop="name">
                    <el-select
                        v-model="formInline.status"
                        placeholder="请选择"
                        clearable
                        @keyup.enter.native="onSubmit"
                    >
                        <el-option
                            v-for="item in rolesList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名" prop="nameReal">
                    <el-input
                        v-model="formInline.nameReal"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="phoneNumber">
                    <el-input
                        v-model="formInline.phoneNumber"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="openId" prop="openid">
                    <el-input
                        v-model="formInline.openid"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" plain @click="addClick"
                    >新增</el-button
                >
								<el-button
										type="primary"
										@click="exportClick()"
										>导入</el-button
								>
                <!-- <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                > -->
            </div>
        </div>
				<!-- 用户导入对话框 -->
				<el-dialog
					:close-on-click-modal="false"
					title="导入用户"
					:visible.sync="dialogExportVisible"
					width="400px"
					append-to-body
				>
					<el-upload
						ref="upload"
						:limit="1"
						accept=".xlsx, .xls"
						:headers="headers"
						:action="actions"
						:disabled="isUploading"
						:on-progress="handleFileUploadProgress"
						:on-success="handleUploadSuccess"
						:auto-upload="false"
						drag
					>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip text-center" slot="tip">
							<span>仅允许导入xls、xlsx格式文件。</span>
							<el-link
								type="primary"
								:underline="false"
								style="font-size: 12px; vertical-align: baseline"
								href="/导入用户.xlsx"
								>下载模板</el-link
							>
						</div>
					</el-upload>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="submitFileForm">确 定</el-button>
						<el-button @click="dialogExportVisible = false">取 消</el-button>
					</div>
				</el-dialog>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="id" width="55" label="#">
                </el-table-column>
                <el-table-column prop="nameReal" label="姓名" width="100">
                </el-table-column>
                <el-table-column prop="phoneNumber" label="手机">
                </el-table-column>
                <el-table-column prop="openid" label="openId">
                </el-table-column>
                <el-table-column prop="job" label="单位职位"> </el-table-column>
                <el-table-column prop="release" label="可视范围">
                    <template
                        slot-scope="scope"
                        v-if="scope.row.cityCode || scope.row.countyCode"
                        >{{ scope.row.cityCode }} -
                        {{ scope.row.countyCode }}</template
                    >
                </el-table-column>
                <el-table-column prop="time" label="注册日期">
                    <template slot-scope="scope" v-if="scope.row.time">{{
                        scope.row.time | dayjs
                    }}</template>
                </el-table-column>
                <el-table-column prop="timeLoginLast" label="最后登录">
                    <template
                        slot-scope="scope"
                        v-if="scope.row.timeLoginLast"
                        >{{ scope.row.timeLoginLast | dayjs }}</template
                    >
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="150">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="editClick(scope.row, false)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            @click="editClick(scope.row, true)"
                            >查看</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-form
                    label-width="140px"
                    :model="formLabelAlign"
                    ref="ruleForm"
                    :rules="rules"
                >
                    <el-form-item label="用户名：" prop="name">
                        <el-input
                            v-model="formLabelAlign.name"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="姓名：" prop="nameReal">
                        <el-input
                            v-model="formLabelAlign.nameReal"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="工作单位、职务：" prop="job">
                        <el-input
                            v-model="formLabelAlign.job"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码：" prop="phoneNumber">
                        <el-input
                            v-model="formLabelAlign.phoneNumber"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="绑定角色：" prop="status">
                        <el-select
                            style="width: 100%"
                            :disabled="disabled"
                            v-model="formLabelAlign.status"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in rolesList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="地市可视范围" prop="cityCode">
                        <!-- <el-input
                            v-model="formLabelAlign.cityCode"
                            :disabled="disabled"
                        ></el-input> -->
                        <!-- cityList -->
                        <el-select
                            v-model="formLabelAlign.cityCode"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="(item, index) in cityList"
                                :key="index"
                                :label="item.areaName"
                                :value="item.areaCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区县可视范围" prop="countyCode">
                        <el-select
                            v-model="formLabelAlign.countyCode"
                            placeholder="请选择"
                            clearable
                            no-data-text="请先选择地市可视范围"
                        >
                            <el-option
                                v-for="(item, index) in countyList"
                                :key="index"
                                :label="item.areaName"
                                :value="item.areaCode"
                            >
                            </el-option>
                        </el-select>
                        <!-- <el-input
                            v-model="formLabelAlign.countyCode"
                            :disabled="disabled"
                        ></el-input> -->
                    </el-form-item>
                </el-form>
            </div>
            <span v-if="!disabled" slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmitClick('ruleForm')"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import {
    adminUserListApi,
    adminUserAddApi,
    adminUserUpdateApi,
    adminUserDeleteApi,
		adminUserDetailApi,
    rolesListApi,
} from '@/api/adminUser'
import { districtListApi } from '@/api/district'
import { getToken } from '@/utils/auth'

export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            rolesList: [],
            title: '',
            dialogVisible: false,
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            formLabelAlign: {},
            disabled: false,
            rules: {
                nameReal: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                ],
                phoneNumber: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur',
                    },
            				{ pattern: /^1\d{10}$/, trigger: "blur", message: "手机号格式错误"  }
                ],
            },
            cityList: [],
            countyList: [],
            message: '',
            actions: process.env.VUE_APP_BASE_API + '/doftec/admin-user/batch/create',
            headers: {
                Authorization: getToken(),
            },
            title: '',
            dialogExportVisible: false,
						isUploading:false
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.adminUserListFun()
        this.rolesListFun()
        this.cityFun()
    },
    watch: {
        'formLabelAlign.cityCode'(n, o) {
            console.log('n,o', n, o)
            this.countyFun()
        },
    },
    methods: {// 导入
        exportClick() {
            this.dialogExportVisible = true
        },
				// 文件上传中处理
				handleFileUploadProgress(event, file, fileList) {
					this.isUploading = true;
				},
        // 上传成功回调
        handleUploadSuccess(res, file) {
					console.log('res, file',res, file);
            if (res.code === 200) {
      				this.$refs.upload.clearFiles();
							this.dialogExportVisible = false;
              this.adminUserListFun()
							this.$alert(
								"<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" +
									res.msg +
									"</div>",
								"导入结果",
								{ dangerouslyUseHTMLString: true }
							);
            } else {
              this.$modal.msgError(res.msg)
            }
						this.isUploading = false;
        },
				// 提交上传文件
				submitFileForm() {
					this.$refs.upload.submit();
				},
        // 请求地区代码
        async cityFun() {
            var params = {
                start: 0,
                length: 9999,
                draw: 1,
                orderStr: 'id desc',
                areaPcode: '370000',
            }
            var res = await districtListApi(params)
            console.log('res', res)
            this.cityList = res.data
        },
        // 请求区县代码
        async countyFun() {
            if (this.formLabelAlign.cityCode) {
                var params = {
                    start: 0,
                    length: 9999,
                    draw: 1,
                    orderStr: 'id desc',
                    areaPcode: this.formLabelAlign.cityCode,
                }
                var res = await districtListApi(params)
                this.countyList = res.data
            } else {
                this.countyList = []
            }
        },
        async adminUserListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                orderStr: 'time desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await adminUserListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
        },
        // 获取角色
        async rolesListFun() {
            var res = await rolesListApi()
            for (let i in res) {
                const element = res[i]
                var obj = {
                    value: Number(i),
                    label: element,
                }
                this.rolesList.push(obj)
            }
        },
        // 批量删除
        allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await adminUserDeleteApi(params)
                            console.log('删除结果', res)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.adminUserListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.adminUserListFun()
            // console.log('onSubmit', this.formInline);
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.adminUserListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            // this.formInline.page = parm.currentPage
            // this.formInline.limit = parm.pageSize
            this.adminUserListFun(parm.currentPage, parm.pageSize)
        },
        // 新增
        addClick() {
            this.dialogVisible = true
            this.disabled = false
            this.title = '新增'
        },
        // 编辑模板
        async editClick(row, disable) {
            console.log('row', row)
						const rowInfo = await this.getInfoFun(row.id)
            console.log('rowInfo', rowInfo)
            this.dialogVisible = true
            this.disabled = disable
            this.formLabelAlign = rowInfo
            if (disable) {
                this.title = '查看'
            } else {
                this.title = '编辑'
            }
        },
				// 获取用户信息
				async getInfoFun(id){
					var params = {
						id
					}
					var res = await adminUserDetailApi(params)
					console.log('res',res);
					if(res.code !== 200){
						this.$message({
							type: 'error',
							message: res.msg,
						})
					}else{
						return res.data
					}
				},
        // 编辑提交
        editSubmitClick(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.dialogVisible = false
                    // this.formLabelAlign={}
                    if (this.formLabelAlign.id) {
                        this.adminUserUpdateFun()
                    } else {
                        this.adminUserAddFun()
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        async adminUserUpdateFun() {
            delete this.formLabelAlign.time
            delete this.formLabelAlign.timeLoginLast
            var res = await adminUserUpdateApi(this.formLabelAlign)
            console.log('res', res)
            if (res.code === 200) {
                this.dialogVisible = false
                this.$message({
                    type: 'success',
                    message: res.message,
                })
                this.adminUserListFun()
            }
        },
        async adminUserAddFun() {
            var res = await adminUserAddApi(this.formLabelAlign)
            if (res.code === 200) {
                this.dialogVisible = false
                this.$message({
                    type: 'success',
                    message: res.message,
                })
                this.adminUserListFun()
            }
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
.down-box {
    margin-bottom: 20px;
}
.down {
	margin-left: 20px;
	font-size: 14px;
	/* color: #333; */
	text-decoration: none;
}
</style>
